<template>
  <v-container>
    <Carousel />
    <v-row>
      <!-- <v-col cols="12" class="d-none d-md-block">
        <ToolbarContainer :menu="menu" />
        <v-toolbar elevation="1" height="25" rounded="b">
          <Breadcrumbs />
        </v-toolbar>
      </v-col> -->
      <v-col cols="12" lg="12">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Carousel from "../Widgets/Carousel.vue";

export default {
	name: "InicioContainer",
	components: {  Carousel },
};
</script>

<style scoped>
</style>