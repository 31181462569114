<template>
    <div>
        <v-row>
            <v-col cols="12" md="6" class="d-none d-md-block"  v-for="(item, i) in items" :key="i"> 
                <a href="https://www.comercialivaipora.com.br/" target="_blank"><v-img :src="item.src" /></a>
            </v-col>
        </v-row>
        <v-row class="d-block d-md-none ma-1 mt-6">
            <v-carousel hide-delimiters cycle continuous height="auto">
                <v-carousel-item v-for="(item,i) in items" :key="i" cover>
                    <a href="https://www.comercialivaipora.com.br/" target="_blank"><v-img :src="item.src" contain max-height="300"/></a>
                </v-carousel-item>
            </v-carousel>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [
                { src: require('@/assets/Banner1.png')  },
                { src: require('@/assets/Banner2.png')}
            ]
        }
    }
}
</script>

<style>

</style>